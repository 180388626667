import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Form, Input, Radio, Checkbox, Button, Select } from 'antd';

import { phoneNumberRule, emailRule, requiredRule } from 'utils/forms';
import { submitFulfillmentForm } from 'services/fulfillment';
import { sendSegment } from 'utils/segment';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as BostaLogo } from 'assets/icons/Bosta_Logo.svg';

import './FulfillmentForm.less';

const GOVERNMENTS = [
  {
    value: 'Alexandria',
    label: 'الإسكندرية'
  },
  {
    value: 'Cairo',
    label: 'القاهرة'
  },
  {
    value: 'Sohag',
    label: 'سوهاج'
  },
  {
    value: 'Menya',
    label: 'المنيا'
  },
  {
    value: 'Dakahlia',
    label: 'الدقهلية'
  },
  // {
  //   value: 'Luxor',
  //   label: 'الأقصر'
  // },
  {
    value: 'Behira',
    label: 'البحيرة'
  },
  {
    value: 'Ismailia',
    label: 'الإسماعيلية'
  },
  {
    value: 'Assuit',
    label: 'أسيوط'
  },
  // {
  //   value: 'Aswan',
  //   label: 'أسوان'
  // },
  {
    value: 'Suez',
    label: 'السويس'
  },
  {
    value: 'Monufia',
    label: 'المنوفية'
  },
  {
    value: 'Sharqia',
    label: 'الشرقية'
  },
  {
    value: 'Gharbia',
    label: 'الغربية'
  },
  {
    value: 'Kafr Alsheikh',
    label: 'كفر الشيخ'
  },
  {
    value: 'El Kalioubia',
    label: 'القليوبية'
  },
  {
    value: 'North Coast',
    label: 'الساحل الشمالي'
  },
  {
    value: 'Qena',
    label: 'قنا'
  },
  {
    value: 'Bani Suif',
    label: 'بني سويف'
  },
  {
    value: 'Damietta',
    label: 'دمياط'
  },
  {
    value: 'Red Sea',
    label: 'البحر الأحمر'
  },
  {
    value: 'Fayoum',
    label: 'الفيوم'
  },
  {
    value: 'Port Said',
    label: 'بور سعيد'
  },
  // {
  //   value: 'New Valley',
  //   label: 'الوادى الجديد'
  // },
  {
    value: 'Giza',
    label: 'الجيزة'
  },
  {
    value: 'Matrouh',
    label: 'مرسى مطروح'
  }
  // {
  //   value: 'North Sinai',
  //   label: 'شمال سيناء'
  // },
  // {
  //   value: 'South Sinai',
  //   label: 'جنوب سيناء'
  // }
];

const FulfillmentForm = ({ intl, history }) => {
  const handleOnFinish = async (values) => {
    try {
      const result = await submitFulfillmentForm(values);
      if (result?.response?.status === 400) {
        notify({
          msg: intl.formatMessage({
            id: 'fulfillment.submission_form.registerd_before_error'
          })
        });
        sendSegment('E_SUBMIT_FULFILLMENTFORM_REGISTERD_BEFORE');
      } else {
        sendSegment('E_SUBMIT_FULFILLMENTFORM_SUCCESS');
        history.push('/fulfillment?success=true');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const shipmentVolume = [
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.shipment_amount_options.1'
      }),
      value: 1
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.shipment_amount_options.2'
      }),
      value: 2
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.shipment_amount_options.3'
      }),
      value: 3
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.shipment_amount_options.4'
      }),
      value: 4
    }
  ];

  const productTypesOptions = [
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.clothes'
      }),
      value: 'Clothes'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.shoes'
      }),
      value: 'Shoes'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.bags'
      }),
      value: 'Bags'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.fashion_accessories'
      }),
      value: 'Fashion accessories'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.mobile_accessories'
      }),
      value: 'Mobile accessories'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.beauty_accessories'
      }),
      value: 'Beauty accessories'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.kitchen_appliances'
      }),
      value: 'Kitchen appliances'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment.submission_form.product_types_options.electronics'
      }),
      value: 'Electronics'
    }
  ];
  return (
    <div className="br-fulfillment-form__container">
      <Form onFinish={handleOnFinish} requiredMark={false}>
        <div className="br-fulfillment-form__first-section-container">
          <div className="br-fulfillment-form__first-section-form-container">
            <Form.Item
              rules={[
                requiredRule(
                  intl.formatMessage(
                    {
                      id: 'form.required_custom'
                    },
                    {
                      label: intl.formatMessage({
                        id: 'contact_sales.form_validation.name'
                      })
                    }
                  )
                )
              ]}
              name="name"
              label={
                <span className="heading-md">
                  {intl.formatMessage({
                    id: 'contact_sales.form_placeholder.name'
                  })}
                </span>
              }
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'contact_sales.form_placeholder.name'
                })}
              />
            </Form.Item>
            <Form.Item
              name="phone"
              label={
                <span className="heading-md">
                  {intl.formatMessage({
                    id: 'contact_sales.form_placeholder.mobile'
                  })}
                </span>
              }
              rules={[
                requiredRule(
                  intl.formatMessage(
                    {
                      id: 'form.required_custom'
                    },
                    {
                      label: intl.formatMessage({
                        id: 'contact_sales.form_validation.phone'
                      })
                    }
                  )
                ),
                phoneNumberRule(
                  intl.formatMessage({
                    id: 'form.phone_not_valid'
                  })
                )
              ]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'contact_sales.form_placeholder.mobile'
                })}
              />
            </Form.Item>
            <Form.Item
              name="email"
              validateTrigger="onBlur"
              rules={[
                requiredRule(
                  intl.formatMessage(
                    {
                      id: 'form.required_custom'
                    },
                    {
                      label: intl.formatMessage({
                        id: 'contact_sales.form_validation.email'
                      })
                    }
                  )
                ),
                emailRule(
                  intl.formatMessage({
                    id: 'form.email_not_valid'
                  })
                )
              ]}
              label={
                <span className="heading-md">
                  {intl.formatMessage({
                    id: 'contact_sales.form_placeholder.email'
                  })}
                </span>
              }
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'contact_sales.form_placeholder.email'
                })}
              />
            </Form.Item>
            <Form.Item
              name="location"
              validateTrigger="onBlur"
              rules={[
                requiredRule(
                  intl.formatMessage(
                    {
                      id: 'form.required_custom'
                    },
                    {
                      label: intl.formatMessage({
                        id: 'fulfillment.submission_form.location_title'
                      })
                    }
                  )
                )
              ]}
              label={
                <span className="heading-md">
                  {intl.formatMessage({
                    id: 'fulfillment.submission_form.location_title'
                  })}
                </span>
              }
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children ?? '').includes(input)
                }
              >
                {GOVERNMENTS.map((city) => (
                  <Select.Option key={city.value} value={city.value}>
                    {city.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="br-fulfillment-form__first-section-text-container">
            <h1 className="header-title">
              {intl.formatMessage({
                id: 'fulfillment.submission_form.title'
              })}
            </h1>
            <h2 className="heading-lg-2">
              {intl.formatMessage({
                id: 'fulfillment.submission_form.second_title'
              })}
            </h2>
            <h4 className="header-subtitle">
              {intl.formatMessage({
                id: 'fulfillment.submission_form.subtitle'
              })}
            </h4>
            <BostaLogo />
          </div>
        </div>
        <div className="br-fulfillment-form__radio-and-checkbox-container">
          <Form.Item
            name="numberOfShipmentsPerMonth"
            label={
              <span className="heading-md">
                {intl.formatMessage({
                  id: 'fulfillment.submission_form.shipments_amount_label'
                })}
              </span>
            }
            colon={false}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'fulfillment.submission_form.shipment_amoint_required'
                })
              }
            ]}
          >
            <Radio.Group>
              {shipmentVolume.map((volume) => (
                <Radio value={volume.value}>
                  <span className="body-medium">{volume.title}</span>
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <div className="br-fulfillment-form__checkbox-container">
            <Form.Item
              name="productsTypes"
              label={
                <span className="heading-md">
                  {intl.formatMessage({
                    id: 'fulfillment.submission_form.product_type_label'
                  })}
                </span>
              }
              colon={false}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'fulfillment.submission_form.product_type_required'
                  })
                }
              ]}
            >
              <Checkbox.Group>
                {productTypesOptions?.map((option) => (
                  <Checkbox value={option.value}>
                    <span className="body-medium">{option.title}</span>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'fulfillment.submission_form.other_types_label'
              })}
              name="otherType"
              colon={false}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {intl.formatMessage({
                  id: 'fulfillment.submission_form.submit'
                })}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default injectIntl(withRouter(FulfillmentForm));
