import { ReactComponent as AmazonLogo } from 'assets/images/Amazon-logo.svg';
import { ReactComponent as PalmaLogo } from 'assets/images/Paima-logo.svg';
import { ReactComponent as CarrefourLogo } from 'assets/images/Carrefour-logo.svg';
import { ReactComponent as EmmaLogo } from 'assets/images/Emma-logo.svg';
import { ReactComponent as JumiaLogo } from 'assets/images/Jumia-logo.svg';
import { ReactComponent as TaagerLogo } from 'assets/images/Taager-logo.svg';
import { ReactComponent as NoonLogo } from 'assets/images/Noon-logo.svg';

export const PARTNERS_MOBILE_CAROUSEL_CONTENT = {
  EG: [
    [<AmazonLogo />, <PalmaLogo />, <CarrefourLogo />, <EmmaLogo />],
    [<JumiaLogo />, <TaagerLogo />, <NoonLogo />]
  ],
  SA: [
    [<AmazonLogo />, <PalmaLogo />, <CarrefourLogo />, <EmmaLogo />],
    [<JumiaLogo />, <TaagerLogo />, <NoonLogo />]
  ]
};

export const PARTNERS = {
  EG: [
    <AmazonLogo />,
    <PalmaLogo />,
    <CarrefourLogo />,
    <EmmaLogo />,
    <JumiaLogo />,
    <TaagerLogo />,
    <NoonLogo />
  ],
  SA: [
    <AmazonLogo />,
    <PalmaLogo />,
    <CarrefourLogo />,
    <EmmaLogo />,
    <JumiaLogo />,
    <TaagerLogo />,
    <NoonLogo />
  ]
};
