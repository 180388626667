import React, { useEffect, Suspense } from 'react';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';

import { sendSegment } from 'utils/segment';

import BRHero from 'components/BRHero/BRHero';
import { notify } from 'components/Notify/Notify';

import './Fulfillment.less';

const SVGS = React.lazy(() => import('./components/LazyLoadSVG/LazyLoadSVG'));

const Fulfillment = ({ intl, history, location }) => {
  useEffect(() => {
    if (location?.search) {
      const success = new URLSearchParams(location.search).get('success');
      if (success) {
        notify({
          msg: intl.formatMessage({
            id: 'fulfillment.submission_form.success_message'
          }),
          type: 'success'
        });
      }
    }
    sendSegment('VISIT_LANDING_PAGE');
  }, []);
  const FAQ_CONTENT_RIGHT = [
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.first_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.first_answer'
      })
    },
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.second_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.second_answer'
      })
    },
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.third_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.third_answer'
      })
    },
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.fourth_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.fourth_answer'
      })
    }
  ];

  const FAQ_CONTENT_LEFT = [
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.fifth_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.fifth_answer'
      })
    },
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.sixth_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.sixth_answer'
      })
    },
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.seventh_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.seventh_answer'
      })
    },
    {
      question: intl.formatMessage({
        id: 'fulfillment.sixth_section.eight_question'
      }),
      answer: intl.formatMessage({
        id: 'fulfillment.sixth_section.eight_answer'
      })
    }
  ];

  const handleOnClickRegisterNow = (sectionName) => {
    history.push({
      pathname: '/fulfillment/form',
      search: location.search
    });
    sendSegment(`E_REGISTER_NOW_BUTTON_CLICKED_${sectionName}`);
  };

  return (
    <Suspense fallback={<img alt="loading..." />}>
      <div>
        <BRHero
          className="br-fulfillment__header"
          title={intl.formatMessage({ id: 'fulfillment.first_section.title' })}
          subtitle={intl.formatMessage({
            id: 'fulfillment.first_section.subtitle'
          })}
          buttonText={intl.formatMessage({
            id: 'fulfillment.first_section.button_text'
          })}
          vectors={[<SVGS id={1} />]}
          onClickButton={() => handleOnClickRegisterNow('FIRST_SECTION')}
          extraInfo={
            <ul>
              <li>
                {intl.formatMessage({
                  id: 'fulfillment.first_section.first_point'
                })}
              </li>
              <li>
                {' '}
                {intl.formatMessage({
                  id: 'fulfillment.first_section.second_point'
                })}
              </li>
            </ul>
          }
        />

        <BRHero
          className="br-fulfillment__header-second"
          title={intl.formatMessage({ id: 'fulfillment.second_section.title' })}
          subtitle={intl.formatMessage({
            id: 'fulfillment.second_section.subtitle'
          })}
          buttonText={intl.formatMessage({
            id: 'fulfillment.second_section.button_text'
          })}
          vectors={[<SVGS id={2} />]}
          onClickButton={() => handleOnClickRegisterNow('SECOND_SECTION')}
        />

        <BRHero
          className="br-fulfillment__header-third"
          title={intl.formatMessage({ id: 'fulfillment.third_section.title' })}
          subtitle={intl.formatMessage({
            id: 'fulfillment.third_section.subtitle'
          })}
          buttonText={intl.formatMessage({
            id: 'fulfillment.third_section.button_text'
          })}
          vectors={[<SVGS id={3} />]}
          onClickButton={() => handleOnClickRegisterNow('THIRD_SECTION')}
        />

        <BRHero
          className="br-fulfillment__header-fourth"
          title={intl.formatMessage(
            { id: 'fulfillment.fourth_section.title' },
            {
              text: (
                <span className="br-fulfillment-extra-data-in-title">
                  {intl.formatMessage({
                    id: 'fulfillment.fourth_section.extra_title'
                  })}
                </span>
              )
            }
          )}
          subtitle={intl.formatMessage({
            id: 'fulfillment.fourth_section.subtitle'
          })}
          vectors={[<SVGS id={4} />]}
          onClickButton={() => handleOnClickRegisterNow('FOURTH_SECTION')}
        />
        <BRHero
          className="br-fulfillment__header-fifth"
          title={intl.formatMessage(
            { id: 'fulfillment.fifth_section.title' },
            {
              text: (
                <span className="br-fulfillment-extra-data-in-title">
                  {intl.formatMessage({
                    id: 'fulfillment.fifth_section.extra_title'
                  })}
                </span>
              )
            }
          )}
          subtitle={intl.formatMessage({
            id: 'fulfillment.fifth_section.subtitle'
          })}
          buttonText={intl.formatMessage({
            id: 'fulfillment.fifth_section.button_text'
          })}
          vectors={[<SVGS id={5} />]}
          onClickButton={() => handleOnClickRegisterNow('FIFTH_SECTION')}
        />
        <section className="br-fulfillment__header-sixth">
          <h1 className="header-title">
            {intl.formatMessage({
              id: 'fulfillment.sixth_section.title'
            })}
          </h1>
          <div className="br-fulfillment__faq-content-container">
            <div className="br-fulfillment__faq-content-container-right-questions">
              {FAQ_CONTENT_RIGHT.map((item) => (
                <div className="br-fulfillment__faq-content-question-content">
                  <span className="heading-md">{item.question}</span>
                  <span>{item.answer}</span>
                </div>
              ))}
            </div>
            <div className="br-fulfillment__faq-content-container-left-questions">
              {FAQ_CONTENT_LEFT.map((item) => (
                <div className="br-fulfillment__faq-content-question-content">
                  <span className="heading-md">{item.question}</span>
                  <span>{item.answer}</span>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="br-fulfillment__header-seventh">
          <div>
            <h2 className="display-md">
              {intl.formatMessage({
                id: 'fulfillment.seventh_section.title'
              })}
            </h2>
            <h1 className="header-title">
              {intl.formatMessage({
                id: 'fulfillment.seventh_section.title_highlight'
              })}
            </h1>
            <h4 className="header-subtitle">
              {intl.formatMessage({
                id: 'fulfillment.seventh_section.subtitle'
              })}
            </h4>
            <h4 className="header-subtitle">
              {intl.formatMessage({
                id: 'fulfillment.seventh_section.second_sub'
              })}
            </h4>
            <Button
              type="primary"
              className="button-primary"
              onClickButton={() => handleOnClickRegisterNow('SEVENTH_SECTION')}
            >
              {intl.formatMessage({
                id: 'fulfillment.seventh_section.button_text'
              })}
            </Button>
            <SVGS id={7} />
          </div>
        </section>
      </div>
    </Suspense>
  );
};

export default injectIntl(withRouter(Fulfillment));
