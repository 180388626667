import { fmt } from 'components/IntlWrapper/IntlWrapper';

import EzzAvatar from 'assets/images/ezz-img.png';
import EcoAvatar from 'assets/images/eco-img.png';
import HoholandAvatar from 'assets/images/hoholand-img.png';
import EmmaLogo from 'assets/images/emma-img.png';
import TaagerLogo from 'assets/images/taager-img.png';
import NoonLogo from 'assets/images/noon-img.png';
import AmazonLogo from 'assets/images/amazon-img.png';
import OolAmeme from 'assets/images/ool-ameme.jpg';
import BFSportsWear from 'assets/images/BF-Sportswear.jpg';
import Dejavu from 'assets/images/Dejavu.jpg';
import Essentials from 'assets/images/Essentials.png';
import JockeyJoy from 'assets/images/Jockey-Joy.jpg';
import Kenzz from 'assets/images/Kenzz.png';
import Khotwah from 'assets/images/Khotwah.jpg';
import Pepla from 'assets/images/Pepla.jpg';
import Snuggs from 'assets/images/Snuggs.jpg';
import SourceBeuty from 'assets/images/Source-beauty.png';
import WayUpSport from 'assets/images/Wayup-Sports.jpg';

import { ReactComponent as FingerPointerIcon } from 'assets/icons/finger-pointer.svg';
import { ReactComponent as FastIcon } from 'assets/icons/fast-time.svg';
import { ReactComponent as PaperIcon } from 'assets/icons/papers.svg';
import { ReactComponent as BankNoteIcon } from 'assets/icons/bank-note.svg';

export const CAPITAL_FEATURES = [
  {
    name: 'easy',
    icon: <FingerPointerIcon />
  },
  {
    icon: <FastIcon />,
    name: 'fast'
  },
  {
    icon: <PaperIcon />,
    name: 'nopaper'
  },
  {
    icon: <BankNoteIcon />,
    name: 'installments'
  }
];

export const COMMENTS = [
  {
    img: EzzAvatar,
    rating: 5,
    name: fmt({
      id: 'bosta_capital.testimonials.comments.ezz.business'
    }),
    position: fmt({
      id: 'bosta_capital.testimonials.comments.ezz.owner'
    }),
    comment: fmt({
      id: 'bosta_capital.testimonials.comments.ezz.comment'
    })
  },
  {
    img: HoholandAvatar,
    rating: 5,
    name: fmt({
      id: 'bosta_capital.testimonials.comments.hoholand.business'
    }),
    position: fmt({
      id: 'bosta_capital.testimonials.comments.hoholand.owner'
    }),
    comment: fmt({
      id: 'bosta_capital.testimonials.comments.hoholand.comment'
    })
  },
  {
    img: EcoAvatar,
    rating: 5,
    name: fmt({
      id: 'bosta_capital.testimonials.comments.eco.business'
    }),
    position: fmt({
      id: 'bosta_capital.testimonials.comments.eco.owner'
    }),
    comment: fmt({
      id: 'bosta_capital.testimonials.comments.eco.comment'
    })
  }
];

export const PARTNERS = [
  EmmaLogo,
  AmazonLogo,
  TaagerLogo,
  NoonLogo,
  OolAmeme,
  BFSportsWear,
  Dejavu,
  Essentials,
  JockeyJoy,
  Kenzz,
  Khotwah,
  Pepla,
  Snuggs,
  SourceBeuty,
  WayUpSport
];

export const STATS = [
  {
    value: '20M+',
    name: fmt({
      id: 'bosta_capital.stats.shipments'
    })
  },
  {
    value: '25k+',
    name: fmt({
      id: 'bosta_capital.stats.client'
    })
  },
  {
    value: '30+',
    name: fmt({
      id: 'bosta_capital.stats.branch'
    })
  },
  {
    value: '1500+',
    name: fmt({
      id: 'bosta_capital.stats.star'
    })
  }
];

export const ERADA_STATS = [
  {
    value: '70+',
    name: fmt({
      id: 'bosta_capital.why_erada.stats.branch'
    })
  },
  {
    value: '1000+',
    name: fmt({
      id: 'bosta_capital.why_erada.stats.employee'
    })
  },
  {
    value: '35k+',
    name: fmt({
      id: 'bosta_capital.why_erada.stats.customer'
    })
  }
];

export const ELIGABLE_PARAGRAPHS = [
  {
    key: 1,
    title: fmt({
      id: 'bosta_capital.eligable.title_one'
    }),
    subtitle: fmt({
      id: 'bosta_capital.eligable.sub_title_one'
    })
  },
  {
    key: 2,
    title: fmt({
      id: 'bosta_capital.eligable.title_two'
    }),
    subtitle: fmt({
      id: 'bosta_capital.eligable.sub_title_two'
    })
  },
  {
    key: 3,
    title: fmt({
      id: 'bosta_capital.eligable.title_three'
    }),
    subtitle: fmt({
      id: 'bosta_capital.eligable.sub_title_three'
    })
  }
];

export const AVG_FINANCING_OPTIONS = [
  { value: '50k  - 100k', label: fmt({ id: 'bosta_capital.form.avg_one' }) },
  { value: '100k - 500k', label: fmt({ id: 'bosta_capital.form.avg_two' }) },
  { value: '500k - 1mil', label: fmt({ id: 'bosta_capital.form.avg_three' }) }
];

export const CAPITAL_VIDEO_URL =
  'https://drive.google.com/file/d/1gDYMysQS3W6z_M0U5S_Imhz86e44e0Xl/preview';

export const CAPITAL_FAQS = [
  {
    key: '0',
    title: fmt({ id: `bosta_capital.faq_0.title` }),
    subtitle: fmt({ id: `bosta_capital.faq_0.subtitle` })
  },
  {
    key: '1',
    title: fmt({ id: `bosta_capital.faq_1.title` }),
    subtitle: fmt(
      { id: `bosta_capital.faq_1.subtitle` },
      {
        span: (children) => (
          <span className="br-capital__span-highlight">{children}</span>
        )
      }
    )
  },
  {
    key: '2',
    title: fmt({ id: `bosta_capital.faq_2.title` }),
    subtitle: fmt({ id: `bosta_capital.faq_2.subtitle` })
  },
  {
    key: '3',
    title: fmt({ id: `bosta_capital.faq_3.title` }),
    subtitle: fmt({ id: `bosta_capital.faq_3.subtitle` })
  }
];
