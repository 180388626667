import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-By': 'Bosta'
};

export const submitFulfillmentForm = async (payload) => {
  try {
    const response = await axios.post(
      `https://tracking.bosta.co/forms/fulfillment`,
      payload,
      {
        headers
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
