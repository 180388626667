import React from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import messages from 'messages';
import {
  flattenMessages,
  getLocaleFromLocalStorage,
  changeDocumentDirection,
  setLocaleInLocalStorage
} from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';

const LANGUGAES = ['ar-eg', 'en-eg', 'en-sa', 'ar-sa'];
const COUNTRIES = ['sa'];
const isFulfillmentPage = window.location.pathname
  .split('/')
  .includes('fulfillment');

const pathLang = !window.location.pathname.split('/')[1].length
  ? getLocaleFromLocalStorage()
  : LANGUGAES.find((str) =>
      str.toLowerCase().includes(window.location.pathname.split('/')[1])
    );

const isURLHavingKSA = COUNTRIES.find((country) =>
  pathLang?.toLowerCase()?.includes(country)
);

const locale = isFulfillmentPage
  ? LOCALE.AR
  : isURLHavingKSA && pathLang === LOCALE.AR_SA.toLowerCase()
  ? LOCALE.AR_SA
  : pathLang === LOCALE.AR.toLowerCase()
  ? LOCALE.AR
  : pathLang || getLocaleFromLocalStorage();

export const cache = createIntlCache();
export let intl = createIntl(
  {
    locale,
    messages: flattenMessages(
      messages[
        locale.toLowerCase() === LOCALE.AR_SA.toLowerCase()
          ? LOCALE.AR_SA
          : locale.toLowerCase() === LOCALE.AR.toLowerCase()
          ? LOCALE.AR
          : locale
      ]
    )
  },
  cache
);

export let fmt = intl.formatMessage;

class IntlWrapper extends React.Component {
  componentDidMount() {
    if (isFulfillmentPage) {
      document.documentElement.lang = LOCALE.AR;
      changeDocumentDirection(LOCALE.AR);
    } else if (pathLang) {
      document.documentElement.lang = pathLang;
      changeDocumentDirection(pathLang);
      setLocaleInLocalStorage(pathLang);
    } else {
      const locale = getLocaleFromLocalStorage();
      document.documentElement.lang = locale;
      changeDocumentDirection(locale);
    }
  }

  render() {
    const { children } = this.props;
    intl = createIntl(
      {
        locale,
        messages: flattenMessages(
          messages[
            locale === LOCALE.AR_SA.toLowerCase()
              ? LOCALE.AR_SA
              : locale === LOCALE.AR.toLowerCase()
              ? LOCALE.AR
              : locale
          ]
        )
      },
      cache
    );
    fmt = intl.formatMessage;

    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
  }
}

export default IntlWrapper;
