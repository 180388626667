import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { ALL_COUNTRIES } from './countries/constants/countries';

export const PRICING_CAROUSEL_DATA = [
  {
    key: 1,
    name: 'Peter Nagy',
    postion: 'Fulfillment Manager of ariika',
    content:
      'I always find the Bosta team helpful. They maintain a fast delivery ecosystem, a great online system to check order status, and are highly reliable.'
  },
  {
    key: 2,
    name: 'Mariam Nagy',
    postion: 'Founder of Mii Bags',
    content:
      'We have been working with Bosta every since the launch of our brand “Mii. We chose Bosta for their technology tools and a team that makes our lives easier and ensures our business runs smoothly.'
  },
  {
    key: 3,
    name: 'Mohamed Shehata',
    postion: 'General Manager of 2S HomeWear',
    content:
      'Almost every good review we receive from a satisfied customer includes a positive review for the fast shipping and consistently outstanding behavior of Bosta Couriers.'
  },
  {
    key: 4,
    name: 'Hager Ali',
    postion: 'Channel Development Manager',
    content:
      'Partnering with bosta is a journey full of success, commitment and delivering results. From one project to another, we are looking forward to many more success stories with bosta.'
  },
  {
    key: 5,
    name: 'Mostafa Magdy',
    postion: 'Founder, Palma',
    content:
      'Bosta is the perfect partner for my shipping needs especially as a startup in Egypt. they are friendly and exceeding my expectation on how they overcome all businesses routine.'
  }
];

export const PRICING_DATA_SOURCE = [
  {
    service: fmt({
      id: 'pricing.table_section.delivery_types.send'
    }),
    cairo: 60,
    alex: 65,
    delta: 70,
    upper: 80
  },
  {
    service: fmt({
      id: 'pricing.table_section.delivery_types.rto_cash_collection'
    }),
    cairo: 50,
    alex: 55,
    delta: 60,
    upper: 70
  },
  {
    service: fmt({
      id: 'pricing.table_section.delivery_types.crp_exchange'
    }),
    cairo: 70,
    alex: 75,
    delta: 80,
    upper: 90,
    className: 'remove-border'
  },
  {
    service: fmt({
      id: 'pricing.table_section.delivery_types.light_bulky'
    }),
    cairo: 160,
    alex: 165,
    delta: 170,
    upper: 180,
    className: 'add-border-top'
  },
  {
    service: fmt({
      id: 'pricing.table_section.delivery_types.heavy_bulky'
    }),
    cairo: 320,
    alex: 370,
    delta: 420,
    upper: 520,
    className: 'add-border-bottom'
  }
];

export const columns = [
  {
    title: fmt({
      id: 'pricing.table_section.service_with_vat'
    }),
    dataIndex: 'service',
    key: 'name'
  },
  {
    title: fmt({
      id: 'pricing.table_section.cairo'
    }),
    dataIndex: 'cairo',
    key: 'age'
  },
  {
    title: fmt({
      id: 'pricing.table_section.alex'
    }),
    dataIndex: 'alex',
    key: 'address'
  },
  {
    title: fmt({
      id: 'pricing.table_section.delta_canal'
    }),
    dataIndex: 'delta',
    key: 'address'
  },
  {
    title: fmt({
      id: 'pricing.table_section.upper_redsea'
    }),
    dataIndex: 'upper',
    key: 'address'
  }
];

export const EGYPT_PICKUP_LOCATION_OPTIONS = {
  1: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.1`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.1`
    }),
    key: 1,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.1`
    })
  },
  2: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.2`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.2`
    }),
    key: 2,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.2`
    })
  },
  3: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.3`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.3`
    }),
    key: 3,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.3`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.3`
    })}`
  },
  4: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.4`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.4`
    }),
    key: 4,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.4`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.4`
    })}`
  },
  5: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.5`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.5`
    }),
    key: 5,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.5`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.5`
    })}`
  },
  6: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.6`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.6`
    }),
    key: 6,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.6`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.6`
    })}`
  },
  7: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.7`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.7`
    }),
    key: 7,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.7`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.7`
    })}`
  },
  8: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.8`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.8`
    }),
    key: 8,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sectors.8`
    })
  },
  9: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.9`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.9`
    }),
    key: 9,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sectors.9`
    })
  }
};

export const SAUDI_PICKUP_LOCATION_OPTIONS = {
  100: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.100`
    }),
    value: ' ',
    key: 100,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.100`
    })
  },
  101: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.101`
    }),
    value: ' ',
    key: 101,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.101`
    })
  },
  102: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.102`
    }),
    value: ' ',
    key: 102,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.102`
    })
  },
  103: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.103`
    }),
    value: ' ',
    key: 103,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.103`
    })
  },
  104: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.104`
    }),
    value: ' ',
    key: 104,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.104`
    })
  }
};

export const PICKUP_LOCATION_OPTIONS =
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code ===
  ALL_COUNTRIES[1].codeName
    ? SAUDI_PICKUP_LOCATION_OPTIONS
    : EGYPT_PICKUP_LOCATION_OPTIONS;

export const BULKY = 'Bulky';
export const LIGHT_BULKY = 'Light Bulky';
export const HEAVY_BULKY = 'Heavy Bulky';
export const XXL = 'XXL';

export const PACKAGE_SIZES = {
  NORMAL: 'Normal',
  LARGE: 'Large',
  X_LARGE: 'X-Large',
  WHITE_BAG: 'White Bag'
};

export const EXTRA_WEIGHT = 'EXTRA_WEIGHT';
export const PERCENTAGE = 'PERCENTAGE';